import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { ionBreakpointMd } from "../../theme/breakpoints.const";
import { Role } from "@services/user/role.enum";
import { AuthService } from "@services/auth/auth.service";
import { Injectable } from "@angular/core";
import { customerPages } from "@services/navigation/paths.const";
import { User } from "@services/user/user.interface";
import { promiseWithTimeout } from "@services/utils/promise.utils";
import { isInKioskMode } from "@services/utils/kiosk-mode.utils";

@Injectable()
export class RedirectToDownloadAppOnDesktopGuard implements CanActivate {
  constructor(private readonly authService: AuthService, private readonly router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Promise<boolean> {
    if (isInKioskMode()) {
      return true;
    }

    let currentUser: User;

    try {
      currentUser = await promiseWithTimeout(this.authService.getActiveUser(), 1000, "timeout fetching user");
    } catch {
      currentUser = await this.authService.getActiveUser();
    }

    if (currentUser?.role === Role.admin || currentUser?.role === Role.driver) {
      return true;
    }

    if (window.innerWidth > ionBreakpointMd) {
      await this.router.navigate([customerPages.downloadTheApp]);
      return false;
    }

    return true;
  }
}
