import { HttpErrorResponse } from "@angular/common/http";
import { ErrorResponseModel } from "../../../functions/src/interfaces/error-model.interface";

export function isHttpError(error: unknown): error is HttpErrorResponse {
  if (!!error && typeof error === "object" && "error" in error) {
    return true;
  }
}

export function isKnownApiError(error: unknown): error is ErrorResponseModel {
  if (
    !!error &&
    typeof error === "object" &&
    "errorCode" in error &&
    typeof error.errorCode === "string" &&
    "errorMessage" in error &&
    typeof error.errorMessage === "string"
  ) {
    return true;
  }
}
