import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { HttpClient } from "@angular/common/http";
import { LoadingService } from "@services/loading/loading.service";
import { firstValueFrom } from "rxjs";
import { isHttpError } from "@services/http/http.utils";
import { promiseWithTimeout } from "@services/utils/promise.utils";

const twentySeconds = 20000;

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(
    private readonly firebaseAuth: AngularFireAuth,
    private readonly http: HttpClient,
    private readonly loadingService: LoadingService,
  ) {}

  public sendGetRequest<T>(endpoint: string): Promise<T> {
    const requestPromise = new Promise<T>(async (resolve, reject) => {
      const authToken = await firstValueFrom(this.firebaseAuth.idToken);

      try {
        const res = await firstValueFrom(
          this.http.get(endpoint, {
            headers: { Authorization: "Bearer " + authToken },
          }),
        );
        resolve(res as T);
      } catch (err) {
        reject(err);
      }
    });

    return promiseWithTimeout(requestPromise, twentySeconds, "Server took too long to respond.");
  }

  public sendPostRequest<Input, Response>(endpoint: string, body: Input): Promise<Response> {
    const requestPromise = new Promise<Response>(async (resolve, reject) => {
      const authToken = await firstValueFrom(this.firebaseAuth.idToken);
      try {
        const res = await firstValueFrom(
          this.http.post(endpoint, body, {
            headers: { Authorization: "Bearer " + authToken },
          }),
        );
        resolve(res as Response);
      } catch (err) {
        if (isHttpError(err)) {
          reject(err.error);
        } else {
          reject(err);
        }
      }
    });

    return promiseWithTimeout(requestPromise, twentySeconds, "Server took too long to respond.");
  }

  public sendPutRequest<Input, Response>(endpoint: string, body: Input): Promise<Response> {
    const requestPromise = new Promise<Response>(async (resolve, reject) => {
      const authToken = await firstValueFrom(this.firebaseAuth.idToken);
      try {
        const res = await firstValueFrom(
          this.http.put(endpoint, body, {
            headers: { Authorization: "Bearer " + authToken },
          }),
        );
        resolve(res as Response);
      } catch (err) {
        if (isHttpError(err)) {
          reject(err.error);
        } else {
          reject(err);
        }
      }
    });

    return promiseWithTimeout(requestPromise, twentySeconds, "Server took too long to respond.");
  }

  public async sendPostRequestWithLoading<Input, Response>(endpoint: string, body: Input): Promise<Response> {
    return this.loadingService.wrapInLoading(async () => this.sendPostRequest<Input, Response>(endpoint, body));
  }
}
