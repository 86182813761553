import { AuthService } from "@services/auth/auth.service";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { Role } from "@services/user/role.enum";
import { adminPages } from "@services/navigation/paths.const";

export const sendAdminsAndDriversToAdminApp = async () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const currentUser = await authService.getActiveUser();
  if (currentUser?.role === Role.admin || currentUser?.role === Role.driver) {
    return router.navigate([adminPages.allOrders]);
  }

  return true;
};
