import { Injectable } from "@angular/core";
import {
  Plan,
  PlanFrequency,
  PlanUniqueId,
  SubscriptionType,
} from "@services/subscriptionType/subscription-type.interface";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { environment } from "@environments/environment";
import { first, map } from "rxjs/operators";
import { BaseCollectionService } from "@services/base-collection.service";
import { firstValueFrom, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SubscriptionTypeService extends BaseCollectionService<SubscriptionType> {
  constructor(protected readonly firestore: AngularFirestore) {
    super(firestore, environment.collections.subscriptionTypes);
  }

  public getPlanFromIdAndFrequency(planId: PlanUniqueId, frequency: PlanFrequency): Observable<Plan> {
    return this.getById(planId)
      .pipe(first())
      .pipe(
        map((subscriptionType) => {
          if (subscriptionType.plans[0].interval === frequency) {
            return subscriptionType.plans[0];
          } else {
            return subscriptionType.plans[1];
          }
        }),
      );
  }

  public async getSubscriptionTypeFromPriceId(priceId: string): Promise<SubscriptionType> {
    const allSubscriptionTypes = await this.getAllSubscriptionTypes();

    for (const type of allSubscriptionTypes) {
      if (type.plans[0].stripeId === priceId) {
        return type;
      }

      if (type.plans[1].stripeId === priceId) {
        return type;
      }
    }

    return undefined;
  }

  public async getAllSubscriptionTypes(): Promise<SubscriptionType[]> {
    const subscriptionTypesCollection = this.firestore.collection<SubscriptionType>(
      environment.collections.subscriptionTypes,
    );

    const result = (await firstValueFrom(subscriptionTypesCollection.get())).docs;

    return result.map((doc) => {
      return { ...doc.data(), id: doc.id } as SubscriptionType;
    });
  }

  public getPlanWithPriceIdFromSubscriptionType(subscriptionType: SubscriptionType, priceId: string): Plan {
    if (subscriptionType.plans[0].stripeId === priceId) {
      return subscriptionType.plans[0];
    }

    if (subscriptionType.plans[1].stripeId === priceId) {
      return subscriptionType.plans[1];
    }

    return undefined;
  }
}
