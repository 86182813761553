import { Environment } from "@environments/environment.interface";

export const environment: Environment = {
  production: true,
  appVersion: "1.9.0",
  stripeKey:
    "pk_live_51IA11DCajej8Q89wqIzEjGBXrHagfs5fNeM1brvea4cMgBcvbvpxIyeWafnYaSoF29g3UwxCOTp1F254bIR29Acb00ePDHlp9m",
  phoneNumber: "+1 (970) 743-9996",
  phoneNumberDisplay: "+19707439996",
  planStripePriceId: {
    initial: {
      smallAnnual: "price_1Js97jCajej8Q89wHbmtWTF4",
      smallMonthly: "price_1Jf8X0Cajej8Q89wiI8tzXcQ",
      mediumAnnual: "price_1Js96cCajej8Q89wnMWoUN8V",
      mediumMonthly: "price_1Jf8ZKCajej8Q89woUCPgA8b",
      largeAnnual: "price_1Js94nCajej8Q89w0xaapdzd",
      largeMonthly: "price_1Jf8aACajej8Q89wfZGr0j06",
    },
    september2023: {
      smallAnnual: "price_1Nq3kxCajej8Q89wHXBFBpKo",
      smallMonthly: "price_1Nq3lPCajej8Q89wlsoQAtVi",
      mediumAnnual: "price_1NqPAfCajej8Q89wGjXZwNAU",
      mediumMonthly: "price_1NqPB9Cajej8Q89wY2OSicBF",
      largeAnnual: "price_1Nq3fECajej8Q89wwEpE9CnV",
      largeMonthly: "price_1Nq3jCCajej8Q89whqslaBG1",
    },
    february2024: {
      individualBootBag: "price_1OZJZ4Cajej8Q89wdTnAeupq",
      individualBootBagWithDelivery: "price_1OhbJcCajej8Q89w7rLg8n9R",
      individualLargeSuitcase: "price_1OTxcHCajej8Q89wJptmuDa0",
      individualLargeSuitcaseWithDelivery: "",
    },
  },
  firebase: {
    apiKey: "AIzaSyCd9NMJXt18AKyPVMo3kMcz669XnpLgYyI",
    authDomain: "clothes-daa4b.firebaseapp.com",
    databaseURL: "https://clothes-daa4b.firebaseio.com",
    projectId: "clothes-daa4b",
    storageBucket: "clothes-daa4b.appspot.com",
    messagingSenderId: "949061229597",
    appId: "1:949061229597:web:6a57b376a9737e85c03af4",
  },
  collections: {
    addresses: "addresses",
    appVersions: "app_versions",
    clothes: "clothes",
    clothes_properties: "clothes_properties",
    driver: "drivers",
    items: "items",
    orders: "orders",
    packlists: "packlists",
    pendingOrders: "pending-orders",
    pendingSubscriptions: "pending-subscriptions",
    plans_options: "plans_options",
    products: "products",
    referrals: "referrals",
    services: "services",
    slides: "slides",
    storage_options: "storage_options",
    subscriptions: "subscriptions",
    subscriptionTypes: "subscriptionTypes",
    users: "users",
    warehouses: "warehouses",
  },
  endpoints: {
    addSource: "https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-addSource",
    addUser: "https://us-central1-clothes-daa4b.cloudfunctions.net/user-addUser",
    backendDomain: "https://prod-dot-clothes-daa4b.uc.r.appspot.com",
    checkIfPaymentRequiredForOrder:
      "https://us-central1-clothes-daa4b.cloudfunctions.net/payment-checkIfPaymentRequiredForOrder",
    createCheckoutSession: "https://us-central1-clothes-daa4b.cloudfunctions.net/checkout-createCheckoutSession",
    createPaymentIntent: "https://us-central1-clothes-daa4b.cloudfunctions.net/payment-createPaymentIntent",
    createReferralCoupon: "https://us-central1-clothes-daa4b.cloudfunctions.net/referral-createReferralCoupon",
    createSubscription: "https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-createSubscription",
    deletePaymentMethod: "https://us-central1-clothes-daa4b.cloudfunctions.net/payment-deletePaymentMethod",
    frontendDomain: "https://app.rocketcloset.com",
    getAddCardIntent: "https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-saveInWallet",
    getCoupon: "https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-getCoupon",
    getPaymentHistory: "https://us-central1-clothes-daa4b.cloudfunctions.net/payment-getPaymentHistory",
    getSubscriptions: "https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-getSubscriptions",
    getSubscriptionsForAdmin: "https://us-central1-clothes-daa4b.cloudfunctions.net/subscription-getSubscriptions",
    getWallet: "https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-getWallet",
    makePaymentForSubscription:
      "https://us-central1-clothes-daa4b.cloudfunctions.net/payment-makePaymentForSubscription",
    orderDeletionRequest: "https://us-central1-clothes-daa4b.cloudfunctions.net/notifications-orderDeletionRequest",
    paySubscription: "https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-paySubscription",
    requestPasswordResetLink: "https://us-central1-clothes-daa4b.cloudfunctions.net/password-requestPasswordResetLink",
    resetPassword: "https://us-central1-clothes-daa4b.cloudfunctions.net/password-resetPassword",
    sendNotification:
      "https://us-central1-clothes-daa4b.cloudfunctions.net/notifications-sendCustomNotificationToUsers",
    updateSubscription: "https://us-central1-clothes-daa4b.cloudfunctions.net/stripe-updateSubscription",
    userHasRecentSubscription: "https://us-central1-clothes-daa4b.cloudfunctions.net/userHasRecentSubscription",
  },
};
