import { Component } from "@angular/core";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AuthService } from "@services/auth/auth.service";
import { User, userToString } from "@services/user/user.interface";
import { environment } from "@environments/environment";
import { Role } from "@services/user/role.enum";
import { Referral } from "@services/referral/referral.interface";
import { referralLocalStorageKey, referralQueryParamKey, ReferralService } from "@services/referral/referral.service";
import { ActivatedRoute } from "@angular/router";
import { adminPages, manageNotificationsPage } from "@services/navigation/paths.const";
import { tryParseDate } from "@services/utils/timestamp.utils";
import { NotificationService } from "@services/notifications/notifications.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  public appPages = [];
  public selectedIndex = 0;
  public appVersion = "";

  public activeUser: User;
  public activeUserReferral: Referral;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService,
    private readonly platform: Platform,
    private readonly referralService: ReferralService,
    private readonly splashScreen: SplashScreen,
    private readonly statusBar: StatusBar,
  ) {
    this.initializeApp();
    this.authService.getActiveUserObservable().subscribe(() => {
      void this.checkMenu();
    });
  }

  public initializeApp(): void {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      await this.notificationService.listenForNotifications();
    });
  }

  public async ngOnInit(): Promise<void> {
    this.appVersion = environment.appVersion;
    await this.storeReferralCoupon();
  }

  private async checkMenu(): Promise<void> {
    this.activeUser = await this.authService.getActiveUser();

    if (!this.activeUser) {
      return;
    }

    this.referralService
      .getReferralByUserId(this.activeUser.id)
      .subscribe((referral) => (this.activeUserReferral = referral));

    switch (this.activeUser.role) {
      case Role.admin:
        this.appPages = [
          {
            title: "Administration",
            children: [
              {
                title: "Items",
                url: "/all-items",
              },
              {
                title: "Orders",
                url: "/all-orders",
              },
              {
                title: "Users",
                url: "/all-users",
              },
              {
                title: "QR Codes",
                url: `/${adminPages.manageRacks}`,
              },
              {
                title: "Notifications",
                url: `/${manageNotificationsPage}`,
              },
              {
                title: "Kiosk Mode",
                url: `/${adminPages.manageKioskMode}`,
              },
            ],
          },
        ];
        break;

      case Role.driver:
        this.appPages = [
          {
            title: "Management",
            children: [
              {
                title: "Orders",
                url: "/all-orders",
              },
            ],
          },
        ];
        break;

      default:
        break;
    }
  }

  public async logout(): Promise<void> {
    await this.authService.logout(() => location.reload());
  }

  private async storeReferralCoupon(): Promise<void> {
    const url = new URL(window.location.href);
    const value = url.searchParams.get(referralQueryParamKey);

    if (value) {
      localStorage.setItem(referralLocalStorageKey, value);
    }
  }

  public readonly userToString = userToString;
  public readonly tryParseDate = tryParseDate;
  protected readonly environment = environment;
}
