import * as Sentry from "@sentry/angular-ivy";
import { environment } from "@environments/environment";

export function logSentry(error: unknown): void {
  Sentry.captureException(error);
}

export function assertNever(_impossibleValue: never): never {
  return _impossibleValue;
}

export function getFrontendDomain(): string {
  return localStorage.getItem("FORCE_LOCAL_DOMAIN") === "true"
    ? "http://localhost:3001"
    : environment.endpoints.frontendDomain;
}

export function getBackendDomain(): string {
  return localStorage.getItem("FORCE_LOCAL_DOMAIN") === "true"
    ? "http://localhost:3001"
    : environment.endpoints.backendDomain;
}
