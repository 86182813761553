import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Router, RouteReuseStrategy } from "@angular/router";
import { ClipboardModule } from "ngx-clipboard";
import * as Sentry from "@sentry/angular-ivy";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./router/app-routing.module";

import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireAuthGuard } from "@angular/fire/compat/auth-guard";
import { environment } from "@environments/environment";

import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { HttpClientModule } from "@angular/common/http";

import { HasActiveSubscriptionGuard } from "@services/router-guards/has-active-subscription.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicStorageModule } from "@ionic/storage-angular";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { Facebook } from "@ionic-native/facebook/ngx";
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { SignInWithApple } from "@ionic-native/sign-in-with-apple/ngx";
import { HasSelectedBaseGuard } from "@services/router-guards/has-selected-base.guard";
import { IsAdminGuard } from "@services/router-guards/is-admin.guard";
import { IsAdminOrDriverGuard } from "@services/router-guards/is-admin-or-driver.guard";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { KioskGuard } from "@services/router-guards/kiosk.guard";
import { NgxPrintModule } from "ngx-print";
import { RedirectToDownloadAppOnDesktopGuard } from "@services/router-guards/redirect-to-download-app-on.desktop.guard";

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    BrowserModule,
    ClipboardModule,
    FormsModule,
    HttpClientModule,
    NgxDatatableModule,
    NgxPrintModule,
    IonicModule.forRoot({ mode: "ios" }),
    IonicStorageModule.forRoot(),
    ReactiveFormsModule,
  ],
  providers: [
    AngularFireAuthGuard,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    Facebook,
    GooglePlus,
    HasActiveSubscriptionGuard,
    HasSelectedBaseGuard,
    HasActiveSubscriptionGuard,
    InAppBrowser,
    IsAdminGuard,
    IsAdminOrDriverGuard,
    KioskGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    RedirectToDownloadAppOnDesktopGuard,
    SignInWithApple,
    SplashScreen,
    StatusBar,
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
