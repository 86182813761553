import { AngularFirestore } from "@angular/fire/compat/firestore";
import { map, mergeMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { deleteUndefinedPropertiesFromObject } from "@services/utils/object.utils";
import firebase from "firebase/compat";
import { Directive } from "@angular/core";
import { Mutex } from "async-mutex";
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import Timestamp = firebase.firestore.Timestamp;
import WhereFilterOp = firebase.firestore.WhereFilterOp;

type BaseDocument = { id?: string; createdAt?: Date | string | Timestamp; updatedAt?: Date | string; userId?: string };
export type BaseDocumentBeforeCreate<Type extends BaseDocument> = Omit<Type, "createdAt" | "id">;
type BaseDocumentForUpdate<Type extends BaseDocument> = Partial<Type>;

type OrderByDirection = "asc" | "desc";

@Directive()
export abstract class BaseCollectionService<ObjectType extends BaseDocument, ObjectTypeForSet = ObjectType> {
  private allObjects: ObjectType[];
  private static mutex: Mutex = new Mutex();

  protected constructor(
    protected readonly firestore: AngularFirestore,
    private readonly collectionName: string,
  ) {}

  public getById(id: string): Observable<ObjectType> {
    const document = this.firestore.collection(this.collectionName).doc<ObjectType>(id).get();

    return document.pipe(
      map((document) => {
        return this.processDocumentSnapshot(document);
      }),
    );
  }

  public getByIds(ids: string[]): Observable<ObjectType[]> {
    return this.getWhere("id", "in", ids);
  }

  public getByUserId(userId: string): Observable<ObjectType[]> {
    return this.getWhere("userId", "==", userId);
  }

  public getFirstByUserId(userId: string): Observable<ObjectType> {
    return this.getWhereFirst("userId", "==", userId);
  }

  public async getAll(): Promise<Observable<ObjectType[]>> {
    return BaseCollectionService.mutex.runExclusive(() => {
      if (!this.allObjects || this.allObjects.length === 0) {
        this.allObjects = [];
        return this.getAllInternal();
      }

      return of(this.allObjects);
    });
  }

  public getFirst(
    orderByFieldPath?: keyof ObjectType & string,
    orderDirection?: OrderByDirection,
  ): Observable<ObjectType> {
    const collection = this.firestore.collection<ObjectType>(this.collectionName, (ref) => {
      let refBuilder = ref.limit(1);

      if (orderByFieldPath && orderDirection) {
        refBuilder = refBuilder.orderBy(orderByFieldPath, orderDirection);
      }

      return refBuilder;
    });

    return collection
      .valueChanges()
      .pipe(
        mergeMap((documents) => {
          return documents ? [documents[0]] : [];
        }),
      )
      .pipe(
        map((document) => {
          return this.processDocument(document);
        }),
      );
  }

  public getWhereFirst(
    whereFieldPath: keyof ObjectType & string,
    whereOpStr: WhereFilterOp,
    whereValue: unknown,
    orderByFieldPath?: keyof ObjectType & string,
    orderDirection?: OrderByDirection,
  ): Observable<ObjectType> {
    const collection = this.firestore.collection<ObjectType>(this.collectionName, (ref) => {
      let query = ref.where(whereFieldPath, whereOpStr, whereValue);

      if (orderByFieldPath && orderDirection) {
        query = query.orderBy(orderByFieldPath, orderDirection);
      }

      return query;
    });

    return collection
      .valueChanges()
      .pipe(
        mergeMap((documents) => {
          return documents ? [documents[0]] : [];
        }),
      )
      .pipe(
        map((document) => {
          return this.processDocument(document);
        }),
      );
  }

  public getWhere(
    fieldPath: keyof ObjectType & string,
    opStr: WhereFilterOp,
    value: unknown,
  ): Observable<ObjectType[]> {
    const collection = this.firestore.collection<ObjectType>(this.collectionName, (ref) =>
      ref.where(fieldPath, opStr, value),
    );

    return collection.valueChanges().pipe(
      map((documents) => {
        return documents.map((doc) => this.processDocument(doc));
      }),
    );
  }

  public async set(doc: BaseDocumentBeforeCreate<ObjectType | ObjectTypeForSet>): Promise<string> {
    const docWithId = { ...doc, id: this.firestore.createId(), createdAt: new Date() } as ObjectType;
    const cleanDocWithId = deleteUndefinedPropertiesFromObject(docWithId);

    await this.firestore.collection<ObjectType>(this.collectionName).doc(docWithId.id).set(cleanDocWithId);

    return docWithId.id;
  }

  public async update(docId: string, doc: BaseDocumentForUpdate<ObjectType>): Promise<void> {
    const docWithId = { ...doc, id: docId } as ObjectType;
    const cleanDocWithId = deleteUndefinedPropertiesFromObject(docWithId);

    return this.firestore.collection(this.collectionName).doc(docId).update(cleanDocWithId);
  }

  public async delete(docId: string): Promise<void> {
    return this.firestore.collection(this.collectionName).doc(docId).delete();
  }

  protected processDocument(document: ObjectType): ObjectType {
    if (!document) {
      return undefined;
    }

    return { ...document, id: document.id };
  }

  private processDocumentSnapshot(document: DocumentSnapshot<ObjectType>): ObjectType {
    if (!document.exists) {
      return undefined;
    }

    return this.processDocument(document.data());
  }

  private getAllInternal(): Observable<ObjectType[]> {
    const collection = this.firestore.collection<ObjectType>(this.collectionName);

    const unique = new Set();

    return collection.valueChanges().pipe(
      map((documents) =>
        documents.map((document) => {
          unique.add(document.id);
          const fullDocument = this.processDocument(document);
          this.allObjects.push(fullDocument);
          return fullDocument;
        }),
      ),
    );
  }
}
